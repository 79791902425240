import { Converter } from '@itm/shared-frontend/lib/utils';
import getEnvVariable from './getEnvVariable';

import {
  TargetPlatform,
  PayrollStatus,
  SourceFileStatus,
  PayrollValidationSeverity,
  ReportStatus,
  SelectOption,
  PartTimeType,
  BreakInServiceType,
  ReportFrequency,
  FrequencyType,
  AuditMethod,
  FileUploadType,
} from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const EMPLOYER_HUB_API_URL = getEnvVariable('EMPLOYER_HUB_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';
export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

export const frequencyOptions: SelectOption[] = Converter.enumToSelectOptions(FrequencyType);
export const uploadStatusOptions: SelectOption[] = Converter.enumToSelectOptions(SourceFileStatus);
export const reportStatusOptions: SelectOption[] = Converter.enumToSelectOptions(ReportStatus);
export const reportFrequencyOptions: SelectOption[] = Converter.enumToSelectOptions(ReportFrequency);

export const fileTypeId = 'f1bf9833-5a29-4092-9b3a-e3aa094eee51';
export const payrollStatusOptions: SelectOption[] = Converter.enumToSelectOptions(PayrollStatus);

export const payrollValidationSeverityOptions: SelectOption[] =
  Converter.enumToSelectOptions(PayrollValidationSeverity);

export const partTimeTypeOptions: SelectOption[] = Converter.enumToSelectOptions(PartTimeType);
export const breakInServiceTypeOptions: SelectOption[] = Converter.enumToSelectOptions(BreakInServiceType);

export const auditMethodOptions: SelectOption[] = [
  { label: 'Manual - Salary details', value: AuditMethod.ManualSalaryDetails },
  { label: 'Manual - Part time details', value: AuditMethod.ManualPartTimeDetails },
  { label: 'Manual - Break in service details', value: AuditMethod.ManualBreakInServiceDetails },
  { label: 'Bulk Upload', value: AuditMethod.BulkUpload },
];

export const MAX_DOWNLOAD_SIZE_IN_BYTES = 52428800; // 50 MB
export const MAX_DOWNLOAD_SIZE_IN_MEGABYTES = Math.ceil(MAX_DOWNLOAD_SIZE_IN_BYTES / 1024 / 1024);

export const fileUploadTypeOptions: SelectOption[] = [
  { label: 'DB Standard', value: FileUploadType.DBStandard },
  { label: 'DC Standard', value: FileUploadType.DCStandard },
];
