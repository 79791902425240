import { employerHubApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { SchemeFileUploadTypeResponse, SchemeResponse } from 'src/types';

export const getSchemeListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<SchemeResponse[]>(`/api/v1/schemes/assignedToCompany/${companyId}`, config);

export const getSchemeById = (schemeId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<SchemeResponse>(`/api/v1/schemes/${schemeId}`, config);

export const getSchemeWithDataAccessListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<SchemeFileUploadTypeResponse[]>(
    `/api/v1/schemes/dataAccessSchemesByCompanyId/${companyId}`,
    config,
  );

export const getSchemeUploadTemplateNotConfiguredForSchemes = (companyId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<boolean>(`/api/v1/schemes/isSchemeUploadTemplateNotConfiguredForSchemes/${companyId}`, config);

export const getUploadTemplateIsDbForAllSchemes = (companyId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<boolean>(`/api/v1/schemes/isUploadTemplateDbForAllSchemes/${companyId}`, config);

export const getDcUploadTemplateSchemes = (companyId: string, config: AxiosRequestConfig = {}) =>
  employerHubApi.get<SchemeFileUploadTypeResponse[]>(`/api/v1/schemes/dcUploadTemplateSchemes/${companyId}`, config);
